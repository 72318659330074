import {Component, OnInit} from '@angular/core';
import {Order} from '../../../domain/models/order/order';
import {Theme} from '../../../domain/models/store/theme';
import {ActivatedRoute, Router} from '@angular/router';
import {StoreService} from '../../../domain/store.service';
import {OrderService} from '../../../domain/order.service';
import {Store} from '../../../domain/models/store/store';
import {ForegroundPaths} from '../../../app-routing.module';
import {CustomerService} from '../../../domain/customer.service';

@Component({
  selector: 'app-complete-receipt',
  templateUrl: './completed-receipt.component.html',
  styleUrls: ['./complete-receipt.component.sass']
})
export class CompletedReceiptComponent implements OnInit {

  order?: Order;
  theme?: Theme;
  store?: Store;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private storeService: StoreService,
    private customerService: CustomerService,
    private orderService: OrderService) {
  }

  async ngOnInit() {
    const orderId = this.route.snapshot.paramMap.get('orderId');
    const storeHandle = this.route.parent?.firstChild?.snapshot.paramMap.get('id')!;
    this.store = await this.storeService.getStore(storeHandle);
    this.theme = this.store.theme;
    const customerId = await this.customerService.getCustomerId();
    if (orderId != null) {
      this.order = await this.orderService.getOrder(customerId, orderId);
    }
  }

  async showReceipt() {
    await this.router.navigate(ForegroundPaths.orderDetails(this.order!.id!));
  }

  async close() {
    await this.router.navigate(ForegroundPaths.empty());
  }
}
