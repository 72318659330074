import {Component, Input} from '@angular/core';
import {Theme} from '../../domain/models/store/theme';
import {EmailDialog} from '../../dialogs/email/email.dialog';
import {EmailService} from '../../domain/email.service';
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {StoreService} from '../../domain/store.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-receipt-email-button',
  templateUrl: './receipt-email-button.component.html',
  styleUrls: ['./receipt-email-button.component.sass']
})
export class ReceiptEmailButtonComponent {

  @Input() orderId?: string;
  @Input() useGiftReceipt?: boolean;
  @Input() theme?: Theme;
  @Input() buttonStyle: 'button' | 'link' = 'button';
  storeHandle?: string | null;
  receiptStatus: boolean = false;

  constructor(private emailService: EmailService,
              private dialog: MatDialog,
              private toastr: ToastrService,
              private translateService: TranslateService,
              private storeService: StoreService,
              private route: ActivatedRoute
  ) {
  }

  async ngOnInit() {
    if (!this.orderId) {
      throw new Error('Attribute \'orderId\' is required');
    }
    if (this.useGiftReceipt == true) {
      const storeHandle = this.route.parent!.firstChild!.snapshot.paramMap.get('id')!;
      const receiptStatus = await this.storeService.isGiftReceiptEnabled(storeHandle);
      if (receiptStatus) {
        this.receiptStatus = true;
      }
      return;
    }

  }

  async sendReceiptAsEmail(orderId: string) {
    const email = await this.dialog.open(EmailDialog).afterClosed().toPromise();
    if (email) {
      console.log('orderId: ' + orderId);
      const result = await this.emailService.sendReceipt(orderId, email).catch(_ => undefined);
      if (result === undefined) {
        console.error('failed to send');
        const message = await this.translateService.get('DIALOG.EMAIL.error').toPromise();
        this.toastr.error(message, undefined, {timeOut: 3000, easeTime: 100, positionClass: 'toast-bottom-center'});
      } else {
        const message = await this.translateService.get('DIALOG.EMAIL.success').toPromise();
        this.toastr.success(message, undefined, {timeOut: 3000, easeTime: 100, positionClass: 'toast-bottom-center'});
      }
    }
  }

  async sendReceiptAsGiftEmail(orderId: string) {
    const email = await this.dialog.open(EmailDialog).afterClosed().toPromise();
    if (email) {
      console.log('orderId: ' + orderId);
      const result = await this.emailService.sendGiftReceipt(orderId, email).catch(_ => undefined);
      if (result === undefined) {
        console.error('failed to send');
      }
      const message = await this.translateService.get('DIALOG.EMAIL.error').toPromise();
      this.toastr.error(message, undefined, {timeOut: 3000, easeTime: 100, positionClass: 'toast-bottom-center'});
    } else {
      const message = await this.translateService.get('DIALOG.EMAIL.success').toPromise();
      this.toastr.success(message, undefined, {timeOut: 3000, easeTime: 100, positionClass: 'toast-bottom-center'});
    }
  }
}
