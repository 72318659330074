import {Component, OnInit} from '@angular/core';
import {Theme} from "../../../../domain/models/store/theme";
import {Store} from "../../../../domain/models/store/store";
import {StoreService} from "../../../../domain/store.service";
import {ActivatedRoute} from "@angular/router";
import {MatBottomSheetRef} from "@angular/material/bottom-sheet";

@Component({
  selector: 'app-random-control',
  templateUrl: './random-control.dialog.html',
  styleUrls: ['./random-control.dialog.sass']
})
export class RandomControlDialog implements OnInit {


  theme?: Theme;
  store?: Store;


  constructor(
    private storeService: StoreService,
    private route: ActivatedRoute,
    private bottomSheetRef: MatBottomSheetRef<RandomControlDialog>
  ) {

  }

  async ngOnInit(): Promise<void> {

    const storeHandle = this.route.parent!.firstChild!.snapshot.paramMap.get('id')!;
    this.store = await this.storeService.getStore(storeHandle);
    this.theme = this.store.theme;
  }


  onAcceptControl() {
    this.bottomSheetRef.dismiss();
  }


}
