<div class="receipt-root">
  <div class="receipt-container">
    <div class="receipt-header">
      <div class="receipt-anim-container"></div>
      <div class="receipt-header-title">
        <ng-lottie
          [options]="{
                  path: './assets/animations/greencheck.json',
                  renderer: 'svg',
                  rendererSettings: {
                    viewBoxSize: '0 0 520 520'
                  },
                  autoplay: true,
                  loop: false
              }">
        </ng-lottie>
      </div>
    </div>

    <h3 class="receipt-payment-title">{{ 'RECEIPT.thanks' | translate }}</h3>
    <span style="color: white">{{ 'RECEIPT.payComplete' | translate }}</span>
  </div>
  <div class="receipt-mail-container">
    <span (click)="close()">{{ 'RECEIPT.close' | translate }}</span>
  </div>
</div>
