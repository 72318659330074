<div >
  <img class="random-check-avatar-image" src="assets/images/flashlight.svg" alt="avatar image">
  <h3 class="random-check-avatar-title" [ngStyle]="{color:theme?.title}">
    {{ 'DIALOG.control' | translate }}
  </h3>
  <h6 class="random-check-message" [ngStyle]="{color:theme?.title}">
    {{ 'DIALOG.randomize' | translate }}
  </h6>
</div>

<div class="random-check-bottom-message">
  <img class="random-check-avatar-image" src="assets/images/thumbsup.svg" alt="avatar image">
  <h3 class="random-check-avatar-title" [ngStyle]="{color:theme?.title}">
    {{ 'DIALOG.doNotWorry' | translate }}
  </h3>
  <h6 class="random-check-message" [ngStyle]="{color:theme?.title}">
    {{ 'DIALOG.verify' | translate }}
  </h6>
</div>

<div class="random-check-button" (click)="onAcceptControl()">
  <button class="button">
    {{ 'DIALOG.randomCheckButton' | translate }}
  </button>
</div>

